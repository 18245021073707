import {
  IS_BANNER_ENABLED_PATH,
  SECTION_HOMEPAGE,
} from '@wix/communities-blog-client-common';
import {
  fetchLastFeaturedPost,
  completeFetchLastFeaturedPost,
} from '../../../common/actions/fetch-last-featured-post';
import { pageOpened } from '../../../common/actions/page-opened';
import type { IWixCodeApi } from '../../../common/controller/wix-code-api';
import { getAppSettingsValue } from '../../../common/selectors/app-settings-base-selectors';
import { isPostContentRequired } from '../../../common/selectors/layout-selectors';
import {
  getIsValidPage,
  getLazyPaginationParams,
  getShowPagination,
} from '../../../common/selectors/pagination-selectors';
import { getFeedPosts } from '../../../common/selectors/post-selectors';
import { translate } from '../../../common/services/controller-translate';
import { FEED_PAGE } from '../../../common/services/detect-route';
import { generateFeedSEOTags } from '../../../common/services/generate-seo-tags/generate-feed-seo-tags';
import { SET_APP_SETTINGS } from '../../../common/store/app-settings/app-settings-actions';
import {
  getBasicParams,
  getIsMobile,
  isEditor,
  isSSR,
  isSite,
} from '../../../common/store/basic-params/basic-params-selectors';
import { fetchProfileUrls } from '../../../common/store/profile-urls/profile-urls-actions';
import { waitForAction } from '../../../common/store/wait-for-action';
import { AppStore, RouteResolverFn } from '../../../common/types';
import { fetchFeedRenderModel } from '../../actions/fetch-feed-render-model';
import { setFeedSEOTags } from '../../actions/set-feed-seo-tags';
import { ROUTE_404, RouteHomeParams } from '../../constants/routes';
import { getFeedSEOTags } from '../../selectors/feed-seo-tags-selectors';
import { getLastFeaturedPost } from '../../selectors/last-featured-post-selectors';

interface CreateFeedPageRouterWithAdapterParams {
  store: AppStore;
  wixCodeApi: IWixCodeApi;
}

export const createFeedPageRouter = ({
  store,
  wixCodeApi,
}: CreateFeedPageRouterWithAdapterParams): RouteResolverFn<RouteHomeParams> => {
  return async ({ params }, redirect, { preFetch, preFetchResult }) => {
    const state = store.getState();

    const page = parseInt(params.page || '1', 10);
    const feedPosts = getFeedPosts(state);
    const basicParams = getBasicParams(state);

    const pageSize = getLazyPaginationParams({
      state,
      section: SECTION_HOMEPAGE,
      page,
    })?.size;

    const actions = preFetchResult || [
      store.dispatch(
        fetchFeedRenderModel({
          page,
          pageSize,
          includeContent: isPostContentRequired(state, SECTION_HOMEPAGE),
          includeInitialPageData: feedPosts.length === 0,
          language: basicParams.language,
        }),
      ),
      dispatchBannerFetch(preFetch ?? false),
    ];

    if (preFetch) {
      return actions;
    }

    if (preFetchResult) {
      const [, bannerPromise] = await preFetchResult;

      if (shouldFetchBanner()) {
        await store.dispatch(completeFetchLastFeaturedPost(bannerPromise));
      }

      store.dispatch(fetchProfileUrls());
    } else {
      await Promise.all(actions);
    }

    if (isInvalidPage()) {
      redirect(ROUTE_404);
    }

    loadFeedSEOTagsIfMissing(page);
    trackPageOpen();
  };

  function dispatchBannerFetch(preFetch: boolean) {
    return store.dispatch(
      waitForAction(
        [SET_APP_SETTINGS],
        () =>
          shouldFetchBanner() ? fetchLastFeaturedPost(preFetch) : () => {},
        true,
      ),
    );
  }

  function shouldFetchBanner() {
    const state = store.getState();

    const isBannerSettingEnabled = getAppSettingsValue({
      state,
      key: IS_BANNER_ENABLED_PATH,
    });

    const hasNoFeaturedPosts = !getLastFeaturedPost(state);

    return isBannerSettingEnabled && hasNoFeaturedPosts;
  }

  function isInvalidPage() {
    const state = store.getState();
    const showPagination = getShowPagination(state, SECTION_HOMEPAGE);
    const isValidPage = getIsValidPage(state, SECTION_HOMEPAGE);
    return showPagination && !isValidPage;
  }

  function loadFeedSEOTagsIfMissing(page: number) {
    const state = store.getState();

    if (!isSite(state) || getFeedSEOTags(state)) {
      return;
    }

    const showPagination = getShowPagination(state, SECTION_HOMEPAGE);

    store.dispatch(
      setFeedSEOTags({
        title: wixCodeApi.seo.title,
        links: wixCodeApi.seo.links,
        metaTags: wixCodeApi.seo.metaTags,
      }),
    );

    const updatedState = store.getState();

    const { title, links, metaTags } = generateFeedSEOTags({
      showPagination,
      state: updatedState,
      page,
      t: translate,
      multilingual: wixCodeApi.window.multilingual,
    });

    wixCodeApi.seo.resetSEOTags();
    wixCodeApi.seo.setLinks(links);
    wixCodeApi.seo.setMetaTags(metaTags);
    wixCodeApi.seo.setTitle(title);
  }

  function trackPageOpen() {
    const state = store.getState();

    if (isSSR(state)) {
      return;
    }

    store.dispatch(
      pageOpened({
        page: FEED_PAGE,
        isMobile: getIsMobile(state),
        isEditor: isEditor(state),
      }),
    );
  }
};
